@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: "Martel Sans", sans-serif;
}

.scroll-bar-cool-1::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

.scroll-bar-cool-1::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 12px;
}

.backg {
  background-color: rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5) 0%,
      rgba(255, 255, 255, 0) 10%
    ),
    linear-gradient(rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 0.7) 96%);
  background-clip: padding-box;
}
